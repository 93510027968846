import { CUSTOM_ERROR_CODES } from '@/constants/error-codes';
import { RootState } from '@/store/redux';
import { ApiErrorRaw } from '@/types/error';
import { APP_ROUTES } from '@/ui/application-routes';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query';
import camelcaseKeys from 'camelcase-keys';

const BASE_URL = import.meta.env.VITE_PILOTEER_API_BASE_URL;
const CLIENT_VERSION = import.meta.env.VITE_CLIENT_VERSION;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set('Client-Version', CLIENT_VERSION);

    const { auth } = getState() as RootState;
    if (auth.session && auth.session.access_token) {
      headers.set('Authorization', `Bearer ${auth.session.access_token}`);
    }
    return headers;
  },
});

export const baseQueryWithIntercept: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (
    result.error?.data &&
    (result.error.data as ApiErrorRaw).error_code ===
      CUSTOM_ERROR_CODES.INVALID_CLIENT
  ) {
    if (window.location.pathname !== APP_ROUTES.INVALID_CLIENT) {
      window.location.replace(APP_ROUTES.INVALID_CLIENT);
    }
  }

  if (result.data) {
    result.data = camelcaseKeys(result.data as Record<string, unknown>, {
      deep: true,
    });
  }

  return result;
};
