import { piloteerApi } from './piloteer';

export const {
  useLazyCheckQuery,
  // User hooks
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useUpdateProfilePictureMutation,
  // Meeting hooks
  useGetAllMeetingsQuery,
  useGetRecentMeetingsQuery,
  useLazyGetMeetingByUuidQuery,
  useGetMeetingByUuidQuery,
  useGetMeetingTranscriptQuery,
  useInviteMutation,
  useGetLeadershipResonancesAggregatedQuery,
  useGetDimensionalReportsAggregatedQuery,
  //Objective hooks
  useGetLatestObjectivesQuery,
  // Subscription hooks
  useCreateSubscriptionCheckoutMutation,
  useCreateCreditPurchaseCheckoutMutation,
  // Integration hooks
  useConnectZoomAccountMutation,
  useDisconnectZoomAccountMutation,
  useConnectGoogleCalendarMutation,
  useDisconnectGoogleCalendarMutation,
} = piloteerApi;

export { piloteerApi };
