import { useAuthSetup } from '@/services/auth';
import { useAppSelector } from '@/store/hooks';
import * as Sentry from '@sentry/react';
import { Suspense } from 'react';
import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { FullPageError } from '../@components/feedback/error/full-page-error';
import { FullPageLoading } from '../@components/feedback/loading/full-page-loading';
import { EnforceAuth } from '../@components/utility/enforce-auth';
import GoogleCalendarOAuthCallback from '../pages/oauth-callback/google';

const ForgotPassword = lazy(() => import('@/ui/pages/auth/forgot-password'));
const Login = lazy(() => import('@/ui/pages/auth/login'));
const ResetPassword = lazy(() => import('@/ui/pages/auth/reset-password/'));
const Signup = lazy(() => import('@/ui/pages/auth/signup'));
const VerifyEmail = lazy(() => import('@/ui/pages/auth/verify-email'));
const SubscriptionSuccess = lazy(
  () => import('@/ui/pages/subscription/success')
);
const SubscriptionCancelled = lazy(
  () => import('@/ui/pages/subscription/cancel')
);
const Dashboard = lazy(() => import('@/ui/pages/dashboard'));
const InvalidClient = lazy(() => import('@/ui/pages/invalid-client'));
const Meetings = lazy(() => import('@/ui/pages/meetings'));
const MeetingReport = lazy(() => import('@/ui/pages/meetings/meeting-report'));
const Pricing = lazy(() => import('@/ui/pages/pricing'));
const Billing = lazy(() => import('@/ui/pages/settings/billing'));
const ProfileSettingsPage = lazy(() => import('@/ui/pages/settings/profile'));
const IntegrationsPage = lazy(() => import('@/ui/pages/settings/integrations'));
const ZoomOAuthCallback = lazy(
  () => import('@/ui/pages/oauth-callback/zoom/zoom')
);

export const APP_ROUTES = {
  ROOT: '/',
  DASHBOARD: '/dashboard',
  PRICING: '/pricing',
  SUBSCRIPTION: {
    SUCCESS: '/subscription/success',
    CANCEL: '/subscription/cancel',
  },
  PURCHASE: {
    SUCCESS: '/purchase/success',
    CANCEL: '/purchase/cancel',
  },
  SETTINGS: {
    MAIN: '/settings',
    PROFILE: '/settings/profile',
    BILLING: '/settings/billing',
    INTEGRATIONS: '/settings/integrations',
  },
  AUTH: {
    MAIN: '/auth',
    LOGIN: '/auth/login',
    SIGNUP: '/auth/signup',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
    VERIFY_EMAIL: '/auth/verify-email',
  },
  MEETINGS: {
    MAIN: '/meetings',
    SLUG: '/meetings/:uuid',
  },
  INVALID_CLIENT: '/invalid-client',
  OAUTH_CALLBACK: {
    ZOOM: '/oauth-callback/zoom',
    GOOGLE: '/oauth-callback/google',
  },
};

const ApplicationRoutes = () => {
  useAuthSetup();
  const { session, isLoading, error } = useAppSelector((state) => state.auth);

  if (isLoading) return <FullPageLoading />;
  if (error) return <FullPageError />;

  const sentryDsn = import.meta.env.VITE_SENTRY_DSN as string;

  const SentryRoutes = sentryDsn
    ? Sentry.withSentryReactRouterV6Routing(Routes)
    : Routes;

  return (
    <SentryRoutes>
      <Route
        path={APP_ROUTES.ROOT}
        element={
          <Navigate
            to={session ? APP_ROUTES.DASHBOARD : APP_ROUTES.AUTH.MAIN}
            replace
          />
        }
      />

      <Route
        path={APP_ROUTES.SUBSCRIPTION.SUCCESS}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <EnforceAuth>
              <SubscriptionSuccess />
            </EnforceAuth>
          </Suspense>
        }
      />
      <Route
        path={APP_ROUTES.SUBSCRIPTION.CANCEL}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <EnforceAuth>
              <SubscriptionCancelled />
            </EnforceAuth>
          </Suspense>
        }
      />

      <Route
        path={APP_ROUTES.PURCHASE.SUCCESS}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <EnforceAuth>
              <SubscriptionSuccess />
            </EnforceAuth>
          </Suspense>
        }
      />
      <Route
        path={APP_ROUTES.PURCHASE.CANCEL}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <EnforceAuth>
              <SubscriptionCancelled />
            </EnforceAuth>
          </Suspense>
        }
      />
      <Route
        path={APP_ROUTES.DASHBOARD}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <EnforceAuth>
              <Dashboard />
            </EnforceAuth>
          </Suspense>
        }
      />

      <Route
        path={APP_ROUTES.PRICING}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <EnforceAuth>
              <Pricing />
            </EnforceAuth>
          </Suspense>
        }
      />

      <Route
        path={APP_ROUTES.SETTINGS.MAIN}
        element={<Navigate to={APP_ROUTES.SETTINGS.PROFILE} />}
      />
      <Route
        path={APP_ROUTES.SETTINGS.PROFILE}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <EnforceAuth>
              <ProfileSettingsPage />
            </EnforceAuth>
          </Suspense>
        }
      />
      <Route
        path={APP_ROUTES.SETTINGS.BILLING}
        element={
          <Suspense>
            <EnforceAuth>
              <Billing />
            </EnforceAuth>
          </Suspense>
        }
      />
      <Route
        path={APP_ROUTES.SETTINGS.INTEGRATIONS}
        element={
          <Suspense>
            <EnforceAuth>
              <IntegrationsPage />
            </EnforceAuth>
          </Suspense>
        }
      />

      {/* Auth */}
      <Route
        path={APP_ROUTES.AUTH.MAIN}
        element={<Navigate to={APP_ROUTES.AUTH.LOGIN} replace />}
      />
      <Route
        path={APP_ROUTES.AUTH.SIGNUP}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <Signup />
          </Suspense>
        }
      />
      <Route
        path={APP_ROUTES.AUTH.LOGIN}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <Login />
          </Suspense>
        }
      />

      <Route
        path={APP_ROUTES.AUTH.FORGOT_PASSWORD}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <ForgotPassword />
          </Suspense>
        }
      />
      <Route
        path={APP_ROUTES.AUTH.RESET_PASSWORD}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <EnforceAuth>
              <ResetPassword />
            </EnforceAuth>
          </Suspense>
        }
      />
      <Route
        path={APP_ROUTES.AUTH.VERIFY_EMAIL}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <VerifyEmail />
          </Suspense>
        }
      />

      {/* Meetings */}
      <Route
        path={APP_ROUTES.MEETINGS.MAIN}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <EnforceAuth>
              <Meetings />
            </EnforceAuth>
          </Suspense>
        }
      />
      <Route
        path={APP_ROUTES.MEETINGS.SLUG}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <EnforceAuth>
              <MeetingReport />
            </EnforceAuth>
          </Suspense>
        }
      />

      <Route
        path={APP_ROUTES.INVALID_CLIENT}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <InvalidClient />
          </Suspense>
        }
      />

      <Route
        path={APP_ROUTES.OAUTH_CALLBACK.ZOOM}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <EnforceAuth>
              <ZoomOAuthCallback />
            </EnforceAuth>
          </Suspense>
        }
      />
      <Route
        path={APP_ROUTES.OAUTH_CALLBACK.GOOGLE}
        element={
          <Suspense fallback={<FullPageLoading />}>
            <EnforceAuth>
              <GoogleCalendarOAuthCallback />
            </EnforceAuth>
          </Suspense>
        }
      />
    </SentryRoutes>
  );
};

export default ApplicationRoutes;
